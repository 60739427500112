import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import { Text } from "../components/Text/Text"
import { Link } from "../components/Link"
import { Container } from "../components/Container.styles"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found" />
    <Container css={{ textAlign: "center" }}>
      <Text as="h1" variant="heading-1">
        Oh dear, this is{" "}
        <u>
          <em>embarrassing.</em>
        </u>
      </Text>
      <Text as="p" variant="body-1">
        This page probably was here at some point. But I've moved things around.
      </Text>
      <Text as="p" variant="body-1">
        If you're super keen to find out what was here,{" "}
        <Link to={"mailto: mail@malicoxon.co.uk"}>drop me an email.</Link>
      </Text>
    </Container>
  </Layout>
)

export default NotFoundPage
